import React from 'react';

export default function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <p className="m-0 text-center">Copyright © 2023 <a href="https://egstars.com" rel="noreferrer" target="_blank" > EG Stars</a></p>
      </div>
    </footer>
  );
}
